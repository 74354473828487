import React, { useState } from "react";
import Layout from "../components/layout";
import PageHeading from "../components/page_heading";
import ResourceData from "../../content/resources.json";
import { SearchBar, TagCloud ,SearchField} from "../components/searchbar";

const ResourcePage = () => {
  const [state, setState] = useState({ tags: new Set(), text: undefined });
  function setTags( newtags ) {
    setState({ tags: newtags, text: state.text });
  }
  function setText(newtext){
    setState({ tags: state.tags, text: newtext });
  }
  return (
    <Layout iconName="resources">
      <PageHeading headingText="Resources" subHeadingText="Useful resources to keep under the radar" />
      <SearchField setState={setText}/>
      <TagCloud state={state.tags} setState={setTags} />
      <SearchBar data={ResourceData} searchTags={state.tags} searchText={state.text}/>
    </Layout>
  );
};
export default ResourcePage;
